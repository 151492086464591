<template>
  <div
    class="transcript-card mb-0 mt-3 p-2"
    @mouseover="handleCommunityBoardMouseOver"
    @mouseleave="handleCommunityBoardMouseLeave"
    @click="handleCommunityBoardClick"
    :class="{
      active: active
    }"
  >
    <h2 class="card-title">{{ title }}</h2>
    <div class="sub-title">{{ communityBoard }}</div>
    <div class="card-subheading mb-0">
      <p class="card-date">{{ formattedDate }}</p>
      <p>
        <a
          target="_blank"
          class="view-transcript-link"
          @click="handleOpenLink(link)"
          >Go To Paragraph in Transcript</a
        >
      </p>
    </div>

    <div class="mb-1 mt-0 pt-0 divider-line"></div>
    <p v-for="(paragraph, index) in searchParagraphs" :key="index">
      <span v-if="index === 1"
        ><strong class="highlight-paragraph">{{ paragraph }}</strong></span
      >
      <span v-else>{{ paragraph }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    communityBoard: String,
    link: String,
    date: String,
    searchParagraphs: Array
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    formattedDate() {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
      }

      return new Date(this.date)
        .toLocaleDateString('en-US', options)
        .replace(/,/, '')
    }
  },
  methods: {
    handleOpenLink(link) {
      const transcriptID = link.split('_id=')[1]
      this.$router.push({
        name: 'Archive',
        query: {
          _id: transcriptID,
          paragraph: this._props.searchParagraphs[1]
        }
      })
    },
    handleCommunityBoardMouseOver() {
      this.active = true
      this.$emit('communityBoardMouseOver', this._props.communityBoard)
    },
    handleCommunityBoardClick() {
      this.$emit('communityBoardClick', this._props.communityBoard)
    },
    handleCommunityBoardMouseLeave() {
      this.active = false
      this.$emit('communityBoardMouseLeave')
    }
  }
}
</script>

<style lang="scss">
.transcript-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin: 16px 0;
  font-size: 12px;
  cursor: pointer;

  &.active {
    border-color: $active-color;
  }
}

.highlight-paragraph {
  background: $paragraph-highlight;
  padding: 4px;
}
.card-subheading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}

.divider-line {
  height: 1px;
  background-color: #e0e0e0;
}

p {
  font-size: 12px;
  margin-bottom: 5px;
}
.view-transcript-link {
  color: #1a73e8 !important;
  text-decoration: none;
}
</style>
