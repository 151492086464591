import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { version } from '../../package.json'

import axios from 'axios'

export default new Vuex.Store({
  state: {
    mobileFlag: false,
    VERSION: version,
    notificationObject: {
      show: false,
      message: '',
      type: ''
    },
    communityBoardInfo: [],
    totalMeetings: 0,
    semanticSearchCards: [],
    semanticSearchError: {
      show: false,
      message: ''
    }
  },
  getters: {
    getMobileFlag: state => {
      return state.mobileFlag
    },
    getVersion: state => {
      return state.VERSION
    },
    getNotificationObject: state => {
      return state.notificationObject
    },
    getCommunityBoardInfo: state => {
      return state.communityBoardInfo
    },
    getTotalMeetings: state => {
      return state.totalMeetings
    },
    getSemanticCards: state => {
      return state.semanticSearchCards
    },
    getSemanticError: state => {
      return state.semanticSearchError
    }
    // getToken: state => {
    //   return state.token
    // }
  },
  mutations: {
    setMobileFlag(state, newMobileFlag) {
      state.mobileFlag = newMobileFlag
    },
    setNotificationObject(state, newNotificationObject) {
      state.notificationObject = newNotificationObject
    },
    setCommunityBoardInfo(state, communityBoardInfo) {
      state.communityBoardInfo = communityBoardInfo
    },
    setTotalMeetings(state, totalMeetings) {
      state.totalMeetings = totalMeetings
    },
    setSemanticSearchCards(state, cards) {
      state.semanticSearchCards = cards
    }
  },
  actions: {
    async readTotalMeetings(context) {
      const params = {
        collectionName: 'transcripts_v4',
        find: {},
        project: { _id: 1 },
        sort: {}
      }

      const response = await axios.post(
        'https://akame.emptybox.io/readTranscripts',
        params
      )

      context.commit('setTotalMeetings', response.data.data.length)
    },
    async readCommunityBoardInfo(context) {
      const params = {
        collectionName: 'community_board_info',
        find: {},
        project: { normalizedName: 1, communityID: 1, status: 1 },
        sort: {}
      }

      const response = await axios.post(
        'https://akame.emptybox.io/readTranscripts',
        params
      )

      context.commit('setCommunityBoardInfo', response.data.data)
    },

    async readSemanticSearch(context, payload) {
      context.state.semanticSearchError.show = false
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/read/search/semantic/transcripts`,
          payload
        )

        if (response.data.data.length === 0) {
          context.state.semanticSearchError.show = true
          context.state.semanticSearchError.message =
            'No Result, please adjust search parameters'
        }
        context.commit('setSemanticSearchCards', response.data.data)
      } catch (error) {
        console.error('err', error)
        context.state.semanticSearchError.show = true
        if (error.response.status >= 500) {
          context.state.semanticSearchError.message =
            'Something went wrong. Please retry your search'
        } else {
          context.state.semanticSearchError.message =
            'No Result, please adjust search parameters'
        }
        context.commit('setSemanticSearchCards', [])
      }
    }
  }
})
