<template>
  <div class="borough-selector">
    <div>
      <button
        v-for="(borough, index) in boroughs"
        :key="index"
        @click="toggleBorough(borough)"
        :class="{ active: isSelected(borough) }"
        class="btn btn-outline-primary rounded-pill"
      >
        {{ borough }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allBoroughs: ['All'],
      defaultBoroughs: [
        'Manhattan',
        'Brooklyn',
        'Queens',
        'Bronx',
        'Staten Island'
      ],
      nonBoroughs: ['NYC Department of City Planning'],
      selectedBoroughs: ['All']
    }
  },
  computed: {
    boroughs() {
      return this.allBoroughs
        .concat(this.nonBoroughs)
        .concat(this.defaultBoroughs)
    }
  },
  mounted() {
    // init send default to parent
    this.emitToParent()
  },
  methods: {
    // emit to parent
    emitToParent() {
      // api prep
      let boroughSelect = this.selectedBoroughs

      // send all boroughs if NYC is selected
      if (this.selectedBoroughs.includes('All')) {
        boroughSelect = this.nonBoroughs.concat(this.defaultBoroughs)
      }

      this.$emit('borough-selected', boroughSelect)
    },
    toggleBorough(borough) {
      if (borough === 'All') {
        if (this.selectedBoroughs.includes('All')) {
          this.selectedBoroughs = []
        } else {
          this.selectedBoroughs = ['All']
        }
      } else {
        const index = this.selectedBoroughs.indexOf(borough)
        if (index !== -1) {
          this.selectedBoroughs.splice(index, 1)
          if (this.selectedBoroughs.length === 0) {
            this.selectedBoroughs.push('All')
          }
        } else {
          // filter out NYC if already selected
          this.selectedBoroughs = this.selectedBoroughs.filter(b => b !== 'All')
          this.selectedBoroughs.push(borough)
        }

        this.emitToParent()
      }
    },
    isSelected(borough) {
      return this.selectedBoroughs.includes(borough)
    }
  }
}
</script>

<style scoped>
.btn:hover,
.btn:active {
  outline: none;
  border-color: #9ff5df;
  background-color: #a0eddae2;
  box-shadow: none;
  color: black;
}
.btn:focus {
  outline: none;
  border: none;
}

.btn {
  font-size: 10px;
  font-weight: 500;
  border: none;
  color: black;
  background-color: #f4f4f4;
  margin: 5px;
  padding: 10px 8px;
  outline: none;
  border: none;
  box-shadow: none !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #9ff5df;
  color: black;
  border-color: #9ff5df;
}

.btn.btn-outline-primary:not(.active):focus {
  background-color: #f4f4f4;
  color: black;
  border-color: #9ff5df;
}

.borough-selector {
  margin-top: 5px;
}
</style>
