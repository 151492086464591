<template>
  <div>
    <b-input-group>
      <b-form-input
        v-model="searchTerm"
        placeholder="search archive by phrase..."
        @keyup.enter="handleSearch"
        @keyup="handleSearch"
        class="rounded-pill search-bar"
      ></b-form-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      searchTerm: ''
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchTerm)
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin-left: -5px;
  margin-right: 5px;
  border: 1px solid;
  border-color: #ced4da;
}
</style>
