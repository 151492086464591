<template>
  <div id="app">
    <Navbar />
    <router-view/>
  </div>
</template>
<script>
import Navbar from "@/components/navbar.vue";

export default {
  components:{
    Navbar
  },
  created(){
    this.mobileFlag()
    window.addEventListener('resize', this.mobileFlag)
    this.$store.dispatch('readCommunityBoardInfo',{})
    this.$store.dispatch('readTotalMeetings',{})
    // this.$store.dispatch('getExternal', {})
  },
  methods:{
    mobileFlag () {
      this.$store.commit('setMobileFlag', 
      window.innerWidth < 768 ? true : false
      )
    }
  }
}
</script>
<style lang="scss">
// goolge font import
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600');
// layout styling
@import "styles/basics.scss";
// layout styling
@import "styles/layout.scss";
@import "styles/typography.scss";

html {
  scroll-behavior: smooth;
}

#app {
  height: 100vh;
  font-family: 'Poppins', sans-serif,Helvetica, Arial, sans-serif, Courier;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  overflow-y: auto;
}
</style>
