<template>
  <div id="Search" class="top-container">
    <div
      id="map-container"
      :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
    >
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>

    <div
      id="MapSidebar"
      class="text-left frosted-glass p-2 sidebar-container overflow"
      :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
    >
      <div class="container">
        <div class="row pt-2">
          <div class="col-12 d-flex flex-column">
            <h3>Semantic Search</h3>
            <p class="sub-title">
              Search NYC community board transcripts by querying phrase or
              question.
            </p>
            <small class="caveat"
              >This feature is in <strong>Beta</strong>, currently supports
              queries for meetings for January 2024</small
            >

            <div class="mb-2 mt-3">
              <div class="header mb-1">Query</div>
              <SearchInput @search="val => (searchTerm = val)" />
            </div>

            <div class="my-2">
              <div class="header mb-1">Select Borough</div>
              <BoroughSelector @borough-selected="handleBoroughSelection" />
            </div>

            <!-- 
            <div class="my-2">
              <div class="header mb-1">Select Date Range</div>
              <DateSelector
                class="w-full"
                v-model="dateRange"
                @date-change="handleDateChange"
                :minDate="'2024-01-01 00:00:00'"
                :maxDate="'2024-01-31 00:00:00'"
              />
            </div>
            -->

            <hr />
            <b-button
              @click="handleSearch"
              variant="outline-success"
              class="rounded-pill search-btn"
              >search</b-button
            >

            <div v-if="semanticSearchError.show" class="text-center">
              <small class="error-message">{{
                semanticSearchError.message
              }}</small>
            </div>
          </div>
        </div>

        <hr />

        <div class="header">
          <span class="bold-header">{{ semanticSearchCards.length }}</span>
          Results
        </div>

        <div v-if="loading" style="height: 25px; width: 25px" class="mx-auto">
          <Spinner />
        </div>

        <div v-if="semanticSearchCards.length > 0">
          <TranscriptCard
            v-for="(card, index) in semanticSearchCards"
            :key="'transcript-search-card-' + index"
            :title="card.transcriptCard.title"
            :date="card.transcriptCard.date"
            :communityBoard="card.transcriptCard.communityBoard"
            :link="card.transcriptCard.link"
            :searchParagraphs="card.searchParagraphs"
            @communityBoardMouseOver="handleTranscriptHover"
            @communityBoardMouseLeave="handleCommunityBoardMouseLeave"
          ></TranscriptCard>
        </div>

        <!-- <div class="row pt-2" v-if="showCommunityBoardInfo">
          <div class="col-12">
            <div class="title">{{ this.communityBoard.normalizedName }}</div>

            <div class="d-flex flex-row align-items-center">
              <socialMedia
                v-if="this.communityBoard.youtubeChannelURL.length > 0"
                :tag="'youtube'"
                :url="
                  'https://www.youtube.com/channel/' +
                  this.communityBoard.youtubeChannelURL
                "
              />
              <socialMedia
                :tag="'twitter'"
                :url="this.communityBoard.twitterURL"
              />
            </div>

            <contactForm
              v-if="this.communityBoard.status === 'active'"
              :communityBoard="communityBoard"
            />
          </div>
        </div>

        <div class="row pt-2" v-if="showErrorMessage">
          <div class="col-12 text-center">
            <h3>😟</h3>
            <div class="text-danger">
              sadly, this community board does not make their meetings
              accessible on YouTube.
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

// mapbox component import
import { MglMap, MglNavigationControl, MglGeolocateControl } from 'vue-mapbox'

import { mapGetters } from 'vuex'

let map = null
// let popup = null;
let featureID = null

import { json, csv } from 'd3-fetch'

import SearchInput from '@/components/SearchInput.vue'
import TranscriptCard from '@/components/TranscriptCard.vue'
// import DateSelector from '@/components/DateSelector.vue'
import BoroughSelector from '@/components/BoroughSelector.vue'
import Spinner from '@/components/Spinner.vue'

let communityInfo = null
let communityDistrictData = null

import * as turf from '@turf/turf'

export default {
  name: 'Search',
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    SearchInput,
    TranscriptCard,
    BoroughSelector,
    Spinner
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      zoom: 10,
      center: [-73.96, 40.76],
      cb: {},
      showCommunityBoardInfo: false,
      showErrorMessage: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedBoroughs: ['All'],
      searchTerm: '',
      activeHighlightCB: '',
      loading: false
    }
  },
  created() {
    // ATTACHES MAP TO INSTANCE
    this.mapbox = Mapbox
  },
  computed: {
    ...mapGetters({
      mobileFlag: 'getMobileFlag',
      communityBoardInfo: 'getCommunityBoardInfo',
      semanticSearchCards: 'getSemanticCards',
      semanticSearchError: 'getSemanticError'
    }),
    flyToZoom() {
      return this.mobileFlag === true ? 10 : 13
    },
    communityBoard() {
      return this.cb
    },
    activeCommunityBoards() {
      return communityInfo.filter(d => {
        d.status === 'active'
      })
    }
  },
  methods: {
    getCommunityInfoObject(communityBoard) {
      return communityInfo.find(d => d.normalizedName === communityBoard)
    },
    handleTranscriptHover(communityBoard) {
      if (communityBoard === undefined) return

      let communityObject = this.getCommunityInfoObject(communityBoard)

      if (communityObject) {
        this.setMapHighlightFromCard(communityObject.communityID, 1)
      }
    },
    handleCommunityBoardMouseLeave() {
      this.setMapHighlightFromCard('', 0.2)
    },
    setMapHighlightFromCard(communityID, highlightValue) {
      if (communityID === undefined) return

      communityDistrictData.features.forEach(d => {
        if (d.properties.communityID === communityID) {
          d.properties['fill-opacity'] = highlightValue
        } else {
          d.properties['fill-opacity'] = 0.2
        }
      })

      map
        .getSource('community-district-source-data')
        .setData(communityDistrictData)
    },
    async loadCommunityDistricts() {
      communityDistrictData = await json(
        './data/community_districts.json',
        () => {}
      ).then(response => {
        return response
      })

      const communityDistrictCentroids = await json(
        './data/community_districts_centroids.json',
        () => {}
      ).then(response => {
        return response
      })

      communityInfo = await csv('./data/community_info.csv', r => {
        return r
      }).then(response => {
        return response
      })

      // let activeCBs = communityInfo
      //   .filter(d => d.status === 'active')
      //   .map(e => {
      //     return e['communityID']
      //   })

      // set default value for opacity
      communityDistrictData.features.forEach(d => {
        d.properties['fill-opacity'] = 0.2
      })

      this.addCommunityDistricts(communityDistrictData)
      this.addCommunityDistrictCentroids(communityDistrictCentroids)

      let extent = turf.bbox(communityDistrictCentroids)
      map.fitBounds(extent)
    },
    addCommunityDistrictCentroids(data) {
      data.features.forEach(d => {
        d.properties['communityDistrictNumber'] = d.properties[
          'communityID'
        ].replace(/^\D+/g, '')
      })

      map.addSource('communityLabels', {
        type: 'geojson',
        data: data
      })

      map.addLayer({
        id: 'community-label',
        type: 'symbol',
        source: 'communityLabels',
        layout: {
          'text-field': '{communityDistrictNumber}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 10
        },
        paint: {
          'text-color': '#333333'
        }
      })
    },
    handleDateChange(newDateRange) {
      this.dateRange = newDateRange
    },
    checkPointInPolygon(lngLat, features) {
      const pt = turf.point(lngLat)

      const filterPoint = features.filter(d => {
        const polygon = turf.polygon(d['geometry']['coordinates'][0])
        return turf.booleanPointInPolygon(pt, polygon)
      })[0]

      const communityBoard = this.getCommunityBoard(
        filterPoint['properties']['communityID']
      )

      this.showCommunityBoardInfo = true
      this.setCommunityBoard(communityBoard)
    },
    addCommunityDistricts(data) {
      map.addSource('community-district-source-data', {
        type: 'geojson',
        data: data,
        generateId: true
      })

      let layers = map.getStyle().layers

      let labelLayerId
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id
          break
        }
      }

      map.addLayer(
        {
          id: 'community-districts',
          source: 'community-district-source-data',
          type: 'fill',
          paint: {
            'fill-color': [
              'match',
              ['get', 'boro'],
              '1',
              '#B6CDE3',
              '2',
              '#E5F2B3',
              '3',
              '#EAD2C8',
              '4',
              '#D5CFE3',
              '5',
              '#CBDED1',
              /* other */ '#ccc'
            ],
            'fill-outline-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#333',
              '#fff'
            ],
            'fill-opacity': ['get', 'fill-opacity']
          }
        },
        labelLayerId
      )

      this.createTooltip('community-districts')
    },
    setCommunityBoard(communityBoard) {
      this.cb = communityBoard
    },
    getCommunityBoard(communityID) {
      return communityInfo.filter(d => {
        return d['communityID'] === communityID
      })[0]
    },
    createTooltip(mapLayer) {
      // popup = new (this).mapbox.Popup({ closeButton: true, closeOnClick: true })

      // let _self = this
      map.on('click', mapLayer, e => {
        e.originalEvent.cancelBubble = true
        this.showCommunityBoardInfo = true

        if (document.querySelector('.mapboxgl-popup') === null) {
          var prop = e.features[0]['properties']

          // const communityBoard = communityInfo.filter((d)=>{
          //   return d['communityID'] === prop['communityID'];
          // })[0];

          const communityBoard = this.getCommunityBoard(prop['communityID'])

          this.setCommunityBoard(communityBoard)

          // let message = "";

          // popup.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
          //   .setHTML(`
          //   <div class='container text-left'>
          //     <div class='row'>
          //       <div class='col'>
          //         <h4>Community Board: ${this.cb}</h4>
          //       </div>
          //     </div>
          //     <div class='row'>
          //       <div class='col'>
          //         <h6>${message}</h6>
          //       </div>
          //     </div>
          //   </div>
          //   `)
          //   .addTo(map)
        }
      })
      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.
      map.on('mousemove', mapLayer, function (e) {
        map.getCanvas().style.cursor = 'pointer'

        if (e.features.length > 0) {
          if (featureID) {
            map.setFeatureState(
              { source: 'community-district-source-data', id: featureID },
              { hover: false }
            )
          }

          let feature = e.features[0]
          featureID = feature.id

          if (feature.properties) {
            this.activeHighlightCB = feature.properties.communityID
          }

          map.setFeatureState(
            { source: 'community-district-source-data', id: featureID },
            { hover: true }
          )
        }
      })

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on('mouseleave', mapLayer, function () {
        map.getCanvas().style.cursor = ''

        this.activeHighlightCB = ''

        if (featureID) {
          map.setFeatureState(
            { source: 'community-district-source-data', id: featureID },
            { hover: false }
          )
        }
        featureID = null
      })
    },
    // FIRES ON MAP LOAD
    onMapLoaded(event) {
      map = event.map
      this.loadCommunityDistricts()
    },
    async handleSearch() {
      this.loading = true

      try {
        // let from, to
        // if (Object.values(this.dateRange).every(d => d === null)) {
        //   let week = getCurrentWeek()
        //   from = week[0]
        //   to = week[1]
        // } else {
        //   from = this.dateRange['startDate']
        //   to = this.dateRange['endDate']
        // }

        const params = {
          query: this.searchTerm,
          startDate: '2024-01-01 00:00:00',
          endDate: '2024-01-31 00:00:00',
          borough: this.selectedBoroughs
        }

        await this.$store.dispatch('readSemanticSearch', params)
      } catch (e) {
        console.error(e)
        this.loading = false

        this.$store.state.semanticSearchError.show = true
        this.$store.state.semanticSearchError.message =
          'No Result, please adjust search parameters'
      }

      this.loading = false
    },
    handleBoroughSelection(boroughs) {
      this.selectedBoroughs = boroughs
    }
  }
}
</script>

<style lang="scss">
.sidebar-container {
  background-color: white;
}

#Map {
  width: calc(100vw - #{$sidebar-width});
  height: 100vh;
  overflow: hidden;
}

.mapboxgl-canvas {
  left: 0px;
}

#map-container {
  position: absolute;
  height: $canvas-height;

  overflow: hidden;

  &.mobile {
    width: 100vw;
    left: 0px;
  }
  &.desktop {
    width: calc(100vw - #{$sidebar-width});
    left: $sidebar-width;
  }
}

.mapboxgl-popup-content {
  // background: #181818;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
// .mapboxgl-popup-close-button{
//   color: #ffffff;
// }
.mapboxgl-ctrl-bottom-left {
  display: none;
}

#DatePicker {
  margin-top: 10px;
}

.search-btn {
  border-color: #ced4da;
  color: black;
  background-color: #f4f4f4;
  outline: none;
  border: none;
  box-shadow: none !important;
  border-color: #9ff5df;
}

.search-btn:hover {
  background-color: #a0eddae2;
  color: black;
  border-color: #9ff5df;
}

.search-btn:not(.active):focus {
  background-color: #f4f4f4;
  color: black;
  outline: none;
}

.search-btn:active {
  background-color: #6c757d;
  color: white;
  transform: translateY(2px);
}

.border-bottom {
  border-bottom: 1px solid;
}
.error-message {
  color: $danger-color;
}
.caveat {
  background: $green;
  border-radius: 8px;
  padding: 5px 5px;
  text-align: center;
  font-size: x-small;
  width: fit-content;
}
</style>
