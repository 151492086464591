<template>
  <div id="Navbar">
    <b-navbar toggleable="lg" class="">
      <b-navbar-brand to="/">
        <div class="logo-img d-flex">
          <img :src="logoImage" alt="block party logo" />
          <div class="logo-brand align-self-center ml-2">block party</div>
          <small style="color: lightgray" class="version-sub"
            >v {{ VERSION }}</small
          >
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            class="pl-2 pr-2"
            :to="item.path"
            v-for="(item, i) in navItems"
            :key="i"
            >{{ item.name }}</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      logoImage: require('@/assets/img/400_logo.png')
    }
  },
  computed: {
    ...mapGetters({
      VERSION: 'getVersion'
    }),
    navItems() {
      return this.$router.options.routes.filter(r => r.link === true)
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.navbar {
  background-color: white !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;

  .nav-item {
    .router-link-exact-active {
      color: $active-color !important;
      font-weight: 700 !important;
    }
  }
  .nav-link {
    &:hover {
      color: $active-color !important;
    }
  }
  .logo-brand,
  .nav-link {
    color: $font-grey !important;
  }
}

.version-sub {
  font-size: x-small;
  margin-left: 4px;
  line-height: 40px;
}

.logo-img {
  height: 40px;
  width: auto;

  img {
    height: 100%;
    width: auto;
  }
}
</style>
